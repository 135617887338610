import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  topUsers,
  topPairs,
} from './dummyData';

import { DashboardService } from '../../../services';

function Dashboard() {
  const { t } = useTranslation();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    DashboardService.getDashboardData()
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.error('Veri alınırken hata oluştu:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!data) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">{t('noDataAvailable')}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      {/* Dashboard Başlık */}
      <Box mb={2}>
        <Typography variant="h4" sx={{ fontWeight: 600, color: '#4a4b4b' }}>
          {t('dashboard')}
        </Typography>
      </Box>

      {/* KPI Kartları */}
      <Box display="flex" flexWrap="wrap" gap={3} mb={3}>
        <Box flex={1} minWidth={250}>
          <Card>
            <CardContent>
              <Typography variant="h6">{t('onlineUsers')}</Typography>
              <Typography variant="h4" color="primary">{data.onlineUsers}/{data.totalUsers}</Typography>
            </CardContent>
          </Card>
        </Box>
        <Box flex={1} minWidth={250}>
          <Card>
            <CardContent>
              <Typography variant="h6">{t('activeMeetings')}</Typography>
              <Typography variant="h4" color="primary">{data.activeMeetings}</Typography>
            </CardContent>
          </Card>
        </Box>
        <Box flex={1} minWidth={250}>
          <Card>
            <CardContent>
              <Typography variant="h6">{t('totalMeetings')}</Typography>
              <Typography variant="h4" color="success">{data.totalMeetings}</Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>

      {/* Toplantı İstatistikleri */}
      <Box display="flex" flexWrap="wrap" gap={3} mb={3}>
        {[{
          title: t('meetingsToday'),
          count: data.meetingsToday,
          duration: data.meetingsTodayDuration,
        }, {
          title: t('meetingsThisWeek'),
          count: data.meetingsThisWeek,
          duration: data.meetingsThisWeekDuration,
        }, {
          title: t('meetingsThisMonth'),
          count: data.meetingsThisMonth,
          duration: data.meetingsThisMonthDuration,
        }, {
          title: t('meetingsThisYear'),
          count: data.meetingsThisYear,
          duration: data.meetingsThisYearDuration,
        }].map((stat, index) => (
          <Box key={index} flex={1} minWidth={250}>
            <Card>
              <CardContent>
                <Typography variant="subtitle1">{stat.title}</Typography>
                <Typography variant="h5">{stat.count} {t('dashboardMeetings')}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {t('totalDuration')}: {stat.duration} {t('minutes')}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Box>

      {/* Devam eden Toplantılar Tablosu */}
      <Box mb={3}>
        <Card>
          <CardContent>
            <Typography variant="h6" mb={2}>{t('activeMeetings')}</Typography>
            <Box>
              {data.activeMeetingsDetails.length > 0 ? (
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr style={{ borderBottom: '2px solid #ddd' }}>
                      <th style={{ padding: '8px', textAlign: 'left' }}>{t('title')}</th>
                      <th style={{ padding: '8px', textAlign: 'left' }}>{t('duration')}</th>
                      <th style={{ padding: '8px', textAlign: 'left' }}>{t('date')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.activeMeetingsDetails.map((meeting) => (
                      <tr key={meeting.id}>
                        <td style={{ padding: '8px' }}>{meeting.title}</td>
                        <td style={{ padding: '8px' }}>{meeting.duration}</td>
                        <td style={{ padding: '8px' }}>{meeting.startDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <Typography>{t('noActiveMeetings')}</Typography>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>

      {/* Geçmiş Toplantılar Tablosu */}
      <Box mb={3}>
        <Card>
          <CardContent>
            <Typography variant="h6" mb={2}>{t('pastMeetings')}</Typography>
            {data.latestMeetings.length > 0 ? (
              <Box>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr style={{ borderBottom: '2px solid #ddd' }}>
                      <th style={{ padding: '8px', textAlign: 'left' }}>{t('title')}</th>
                      <th style={{ padding: '8px', textAlign: 'left' }}>{t('duration')}</th>
                      <th style={{ padding: '8px', textAlign: 'left' }}>{t('date')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.latestMeetings.map((meeting) => (
                      <tr key={meeting.id}>
                        <td style={{ padding: '8px' }}>{meeting.title}</td>
                        <td style={{ padding: '8px' }}>{meeting.duration}</td>
                        <td style={{ padding: '8px' }}>{meeting.dateTime}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            ) : (

              <Typography sx={{ color: 'grey.600' }}>
                {t('noPastMeetings')}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Box>

      {/* Son Kullanıcılar Tablosu */}
      <Box mb={3}>
        <Card>
          <CardContent>
            <Typography variant="h6" mb={2}>{t('latestUsers')}</Typography>
            <Box>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr style={{ borderBottom: '2px solid #ddd' }}>
                    {/*  <th style={{ padding: '8px', textAlign: 'left' }}>{t('id')}</th> */}
                    <th style={{ padding: '8px', textAlign: 'left' }}>{t('fullName')}</th>
                    <th style={{ padding: '8px', textAlign: 'left' }}>{t('email')}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.latestUsers.map((user, index) => (
                    <tr key={index}>
                      {/*  <td style={{ padding: '8px', textAlign: 'left' }}>{user.id}</td> */}
                      <td style={{ padding: '8px', textAlign: 'left' }}>{user.fullName}</td>
                      <td style={{ padding: '8px', textAlign: 'left' }}>{user.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </CardContent>
        </Card>
      </Box>

      {/* En İyi Kullanıcılar Tablosu */}
      <Box mb={3}>
        <Card>
          <CardContent>
            <Typography variant="h6" mb={2}>{t('topUsers')}</Typography>
            <Box>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr style={{ borderBottom: '2px solid #ddd' }}>
                    <th style={{ padding: '8px', textAlign: 'left' }}>{t('user')}</th>
                    <th style={{ padding: '8px', textAlign: 'left' }}>{t('dashboardMeetings')}</th>
                  </tr>
                </thead>
                <tbody>
                  {topUsers.map((user, index) => (
                    <tr key={index}>
                      <td style={{ padding: '8px', textAlign: 'left' }}>{user.name}</td>
                      <td style={{ padding: '8px', textAlign: 'left' }}>{user.meetings}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </CardContent>
        </Card>
      </Box>

      {/* En İyi Çiftler Tablosu */}
      <Box mb={3}>
        <Card>
          <CardContent>
            <Typography variant="h6" mb={2}>{t('topPairs')}</Typography>
            <Box>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr style={{ borderBottom: '2px solid #ddd' }}>
                    <th style={{ padding: '8px', textAlign: 'left' }}>{t('pair')}</th>
                    <th style={{ padding: '8px', textAlign: 'left' }}>{t('dashboardMeetings')}</th>
                  </tr>
                </thead>
                <tbody>
                  {topPairs.map((pair, index) => (
                    <tr key={index}>
                      <td style={{ padding: '8px' }}>{pair.pair}</td>
                      <td style={{ padding: '8px' }}>{pair.meetings}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </CardContent>
        </Card>
      </Box>


    </Box>
  );
}

export default Dashboard;
