import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import {Divider, List, ListItemText, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import HomeIcon from '@mui/icons-material/Home';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import store from '../../../../../../redux/store';
import {changeAuthentication, setSidebarOpen} from '../../../../../../redux/actions';
import LogoS from 'svg/logos/LogoBeyaz.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import {useTranslation} from 'react-i18next';
import Button from '@mui/material/Button';
import LanguageIcon from '@mui/icons-material/Language';



const SidebarNav = () => {

  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const isAuthenticated = useSelector((state) => state.main.isAuthenticated);
  const isMobile = useSelector((state) => state.main.isMobile);
  const user = useSelector((state) => state.main.user);
  const [currentLanguage, setCurrentLanguage] = React.useState('Türkçe');
  const [languageOpen, setLanguageOpen] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [cookie, setCookie, removeCookie] = useCookies(['loginToken']);
  const selectedStyle = {backgroundColor: '#f0f5ff', color: 'red', '&:hover': {backgroundColor: 'grey'}};
  const deSelectedStyle = {color: 'red'};
  const [currentPage, setCurrentPage] = useState('sdssd');
  const [menuList, setMenuList] = useState([
    {
      page: '/home',
      isLang: false,
      title: t('main'),
      icon: <HomeIcon sx={{color: 'red'}}/>,
      isOpen: false
    },
    {
      page: '/users',
      isLang: false,
      title: t('userManagement'),
      icon: <AccountCircleIcon sx={{color: 'red'}}/>,
      isOpen: false,
      children: [
        {
          page: '/users?isActive=true&pageNumber=1&rowCount=10&sortDirection=1',
          isLang: false,
          title: t('activeUsers')
        },
        {
          page: '/users?isActive=false&pageNumber=1&rowCount=10&sortDirection=1',
          isLang: false,
          title: t('deactiveUsers')
        }
      ]
    },
    {
      page: '/page-pricing',
      isLang: false,
      title: t('billing'),
      icon: <InsertDriveFileIcon sx={{color: 'red'}}/>,
      isOpen: false,
      children: [
        {
          page: '/page-pricing/pricing',
          isLang: false,
          title: t('buyNow')
        },
        {
          page: '/page-pricing/products',
          isLang: false,
          title: t('yourProducts')
        }
      ]
    }
  ]);
  const onSidebarClose = () => {
    store.dispatch(setSidebarOpen(false));
  };
  const onMenuClick = (item) => {
    if (item.children) {
      const data = [...menuList];
      data.forEach((menuItem) => {
        if (menuItem.page === item.page) {
          menuItem.isOpen = !item.isOpen;
        }
      });
      setMenuList(data);
    } else {
      history.push(item.page);
      if (isMobile) {
        onSidebarClose();
      }
    }
  };
  useEffect(() => {
    {/*setCurrentLanguage(i18n.language === 'tr' ? 'Türkçe' : 'English');
    */}
    setMenuList([
      {
        page: '/home',
        isLang: false,
        title: t('main'),
        icon: <HomeIcon sx={{color: 'red'}}/>,
        isOpen: false
      },
      {
        page: '/users',
        isLang: false,
        title: t('userManagement'),
        icon: <AccountCircleIcon sx={{color: 'red'}}/>,
        isOpen: false,
        children: [
          {
            page: '/users?isActive=true&pageNumber=1&rowCount=10&sortDirection=1',
            isLang: false,
            title: t('activeUsers')
          },
          {
            page: '/users?isActive=false&pageNumber=1&rowCount=10&sortDirection=1',
            isLang: false,
            title: t('deactiveUsers')
          }
        ]
      },
      {
        page: '/page-pricing',
        isLang: false,
        title: t('billing'),
        icon: <InsertDriveFileIcon sx={{color: 'red'}}/>,
        isOpen: false,
        children: [
          {
            page: '/page-pricing/pricing',
            isLang: false,
            title: t('buyNow')
          },
          {
            page: '/page-pricing/products',
            isLang: false,
            title: t('yourProducts')
          }
        ]
      }
    ]);
  }, [i18n.language]);

  useEffect(() => {
    const pathname = history.location.pathname + history.location.search;
    setCurrentPage(pathname);
    const data = [...menuList];
    data.forEach((menuItem) => {
      menuItem.isOpen = pathname.includes(menuItem.page);
    });
    setMenuList(data);
  }, [history.location]);

  const changeLanguage = (lang) => {
    setCurrentLanguage(lang);
    switch (lang) {
      case 'Türkçe':
        i18n.changeLanguage('tr');
        break;
      case 'English':
        i18n.changeLanguage('en');
        break;
      case 'Arabic':
        i18n.changeLanguage('ar');
        break;
      case 'Russian':
        i18n.changeLanguage('ru');
        break;
      default:
        i18n.changeLanguage('en');
    }
    setLanguageOpen(false);
  };

  const logOut = () => {
    store.dispatch(changeAuthentication(false));
    history.push('/');
  };


  return (
    <Box style={{position: 'relative', paddingTop: '32px'}}>
      {
        isMobile && <Box
          style={{position: 'absolute', right: '10px', top: '10px'}}
          onClick={() => onSidebarClose()}
        >
          <IconButton>
            <CloseIcon fontSize="small" style={{color: 'red'}}/>
          </IconButton>
        </Box>

      }
      <Box style={{textAlign: 'center'}} paddingTop={5}>
        <Box
          to="/home"
          component={Link}
          display={'flex'}
          justifyContent={'center'}
          marginLeft={'auto'}
          marginRight={'auto'}
          marginBottom={3}
          sx={{height: {md: '39px', xs: '20px'}, width: {md: '100px', xs: '100px'}}}>
          <img style={{maxWidth: '100px', width: '100%', height: '100%'}} src={LogoS}/>
          <Typography
            variant='h6'
            component='span'
            sx={{
              marginLeft: 1,
              fontWeight: 'bold',
              color: '#b8c0c0',
              display: 'flex',
              alignItems: 'center', // Center text vertically
            }}
          >
            Telepati
          </Typography>
        </Box>

      </Box>

      <Box>
        <AccountCircleIcon sx={{width: '100%', height: 110, color: 'red'}}/>

        <Typography
          sx={{textAlign: 'center', mb: 2, color: 'red'}}>{t('hello') + ' ' + (user && user.name)}</Typography>
      </Box>
      <Divider color="gray"/>

      <List component="nav" sx={{width: '100% !important', height: 'calc(100vh - 367px)'}}>
        {
          menuList.map((item, index) => {
            const Icon = () => item.icon;
            return (
              <div key={index}>
                <ListItemButton
                  key={index}
                  sx={currentPage.includes(item.page) ? selectedStyle : deSelectedStyle}
                  onClick={() => onMenuClick(item)}>
                  <ListItemIcon>
                    <Icon/>
                  </ListItemIcon>
                  <ListItemText
                    primary={item.isLang ? t(item.title) : item.title}
                    sx={{color: 'black'}}/>
                  {
                    item.children && (item.isOpen ? <ExpandLess/> : <ExpandMore/>)
                  }
                </ListItemButton>
                <Collapse in={item.isOpen} timeout="auto" unmountOnExit>
                  <List component="div" style={{backgroundColor: 'green'}} disablePadding>
                    {
                      item.children && item.children.map((childrenItem, childrenIndex) => {
                        return (
                          <ListItemButton
                            key={'children-' + childrenIndex}
                            style={{
                              color: 'black',
                              backgroundColor: currentPage === childrenItem.page ? '#f6f0f0' : '#f0f5ff'
                            }}
                            onClick={() => {
                              if (isMobile) {
                                onSidebarClose();
                              }
                              history.push(childrenItem.page);
                            }}>
                            <ListItemText
                              primary={childrenItem.isLang ? t(childrenItem.title) : childrenItem.title}
                              sx={{pl: 8}}/>
                          </ListItemButton>
                        );
                      })
                    }
                  </List>
                </Collapse>
              </div>
            );
          })
        }
        <ListItemButton
          onClick={() => {
            logOut();
          }}>
          <ListItemIcon>
            <LogoutIcon sx={{color: 'red'}}/>
          </ListItemIcon>
          <ListItemText
            primary={t('logout')}
            sx={{color: 'black'}}
          />
        </ListItemButton>

      </List>

      <Box style={{position: 'relative', textAlign: 'center'}}>
        {languageOpen &&
          <div style={{ backgroundColor: '#f0f5ff', position: 'absolute', left: '0', right: '0', bottom: '45px' }}>
            <div style={{ display: currentLanguage !== 'Türkçe' ? 'flex' : 'none' }}>
              <Divider />
              <Button
                fullWidth
                sx={{
                  color: 'red',
                  marginTop: 1,
                  borderRadius: 0,
                  '&:hover': {
                    backgroundColor: 'red'
                  }

                }}
                variant="text"
                onClick={() => changeLanguage('Türkçe')}
              >
                Türkçe
              </Button>
            </div>
            <div style={{ display: currentLanguage !== 'English' ? 'flex' : 'none' }}>
              <Divider />
              <Button
                fullWidth
                sx={{
                  color: 'red',
                  marginTop: 1,
                  borderRadius: 0,
                  '&:hover': {
                    backgroundColor: '#f0f5ff'
                  }
                }}
                variant="text"
                onClick={() => changeLanguage('English')}
              >
                English
              </Button>
            </div>
            <div style={{ display: currentLanguage !== 'Arabic' ? 'flex' : 'none' }}>
              <Divider />
              <Button
                fullWidth
                sx={{
                  color: 'red',
                  marginTop: 1,
                  borderRadius: 0,
                  '&:hover': {
                    backgroundColor: '#f0f5ff'
                  }
                }}
                variant="text"
                onClick={() => changeLanguage('Arabic')}
              >
                Arabic
              </Button>
            </div>
            <div style={{ display: currentLanguage !== 'Russian' ? 'flex' : 'none' }}>
              <Divider />
              <Button
                fullWidth
                sx={{
                  color: 'red',
                  marginTop: 1,
                  borderRadius: 0,
                  '&:hover': {
                    backgroundColor: '#f0f5ff'
                  }
                }}
                variant="text"
                onClick={() => changeLanguage('Russian')}
              >
                Русский
              </Button>
            </div>
          </div>
        }

        <Divider />
        <Button
          startIcon={<LanguageIcon />}
          sx={{
            color: 'red',
            width: 100,
            marginTop: 1,
            marginRight: 1,
            borderRadius: 5
          }}
          variant="text"
          onClick={() => setLanguageOpen(!languageOpen)}
        >
          {currentLanguage}
        </Button>
      </Box>
    </Box>
  );
};


SidebarNav.propTypes = {
  onClose: PropTypes.func
};

export default SidebarNav;
