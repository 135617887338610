import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import {useHistory} from 'react-router-dom';
import LogoS from 'svg/logos/LogoBeyaz.png';
import store from '../../../../redux/store';
import {setSidebarOpen} from '../../../../redux/actions';

const Topbar = () => {
  let history = useHistory();



  const onSidebarOpen = () => {
    store.dispatch(setSidebarOpen(true));
  };


  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
    >
      <Box
        display="flex"
        alignItems="flex-start" /* Üst tarafa hizalar */
        width="100%"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start" /* Logoyu sola hizalar */
        >
          <Box
            component="a"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push('/home')}
            margin="0" /* Ekstra boşluk olmaması için */
          >
            <img style={{ maxWidth: '60px' }} src={LogoS} alt="Logo" />
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems={'center'}>
        <Box
          marginRight={{xs: 1, sm: 2}}
          sx={{display: {xs: 'flex', md: 'none'}, backgroundColor: 'gray', borderRadius: 5}}
        >
          <IconButton onClick={onSidebarOpen} aria-label="Menu">
            <MenuIcon sx={{color: 'white'}}/>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func
};


export default Topbar;

