import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import { Topbar, Sidebar } from './components';
import Container from 'common/Container';
import {useSelector} from 'react-redux';
import axios from 'axios';
import {setIsCurrencyDollar, setPackages} from '../../redux/actions';
import store from '../../redux/store';
import {useTranslation} from 'react-i18next';
import {PricingServices} from '../../services';

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

HideOnScroll.propTypes = {
  children: PropTypes.node.isRequired,
};

const Main = ({
  children,
  themeToggler,
  themeMode,
  setThemePalette,
  paletteType,
}) => {
  const sidebarWidth = useSelector((state) => state.main.sidebarWidth);
  const packages = useSelector((state) => state.main.packages);
  const isDollar = useSelector((state) => state.main.isCurrencyDollar);
  const {i18n} = useTranslation();
  
  useEffect(() => {
    setDefaultLang();
  }, []);


  useEffect(() => {
    getAllProduct();
  }, [isDollar]);

  function setDefaultLang() {
    if (localStorage.getItem('lngChnged') !== 'true') {
      axios.get('https://www.iplocate.io/api/lookup/').then((response) => {
        if (response.data.country_code !== 'TR') {
          i18n.changeLanguage('en');
          localStorage.setItem('i18nextLng', 'en');
        } else {
          i18n.changeLanguage('tr');
          localStorage.setItem('i18nextLng', 'tr');
        }
        store.dispatch(setIsCurrencyDollar(response.data.country_code !== 'TR'));
      }).catch(() => localStorage.setItem('i18nextLng', 'tr'));
    }
  }


  function getAllProduct() {
    PricingServices.getAllPackages(isDollar).then(res => {

      let localPackages = [...packages];

      let standartLocal = localPackages.filter(x => x.id == 'standart')[0];
      let businessLocal = localPackages.filter(x => x.id == 'business')[0];
      let standartDB = res.data.filter(x => x.title == 'Standard')[0];
      let businessDB = res.data.filter(x => x.title == 'Business')[0];

      standartLocal.monthlyAmount = Number.parseFloat(standartDB?.price.toString().replace(',', '.')).toFixed(2);
      standartLocal.annualAmount = (Number.parseFloat(standartDB?.price.toString().replace(',', '.')) * 12).toFixed(2);
      businessLocal.monthlyAmount = Number.parseFloat(businessDB?.price.toString().replace(',', '.')).toFixed(2);
      businessLocal.annualAmount = (Number.parseFloat(businessDB?.price.toString().replace(',', '.')) * 12).toFixed(2);

      store.dispatch(setPackages(localPackages));
    });
  }


  return (
    <div>
      <HideOnScroll>
        <AppBar
          position={'fixed'}
          elevation={1}
          sx={{ background: '#f7f7fa ',
            display: {md: 'none', sm: 'block'}}}
        >
          <Container paddingY={{ xs: 3 / 4, sm: 2 }} >
            <Topbar
              themeMode={themeMode}
              themeToggler={themeToggler}
              setThemePalette={setThemePalette}
              paletteType={paletteType}
            />
          </Container>
        </AppBar>
      </HideOnScroll>
      <Box style={{display:'flex'}}>
        <Sidebar/>
        <Box component={'main'} 
          sx={{ width:{ md: `calc(100% - ${sidebarWidth}px)`}, marginLeft:{md:`${sidebarWidth}px`}}}
          maxHeight={'100vh'}
          overflow={'auto'}>
          {children}
        </Box>
      </Box>
      {/*<Box sx={{backgroundColor: '#6D6B70 ', marginTop: '-1px'}}>*/}
      {/*  <Container paddingY={2} >*/}
      {/*    <Footer />*/}
      {/*  </Container>*/}
      {/*</Box>*/}
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  setThemePalette: PropTypes.func.isRequired,
  paletteType: PropTypes.string.isRequired,
};

export default Main;
