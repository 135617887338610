import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';

import Turkish from 'locales/tr';
import English from 'locales/en';
import Arabic from 'locales/ar';
import Russian from 'locales/ru';

const resources = {
  en: {
    translation: English
  },
  tr: {
    translation: Turkish
  },
  ru: {
    translation: Russian
  },
  ar: {
    translation: Arabic
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'tr',
    supportedLngs: ['en', 'tr', 'ru', 'ar'],
    debug: false,

    interpolation: {
      escapeValue: false,
    }
  });


export default i18n;
